<template>

    <div id="fullbox">
        <div :style="{width:  percents[0]+'%'}" class="green" v-if="percents[0] > 0"></div>
        <div :style="{width:  percents[1]+'%'}" class="yellow" v-if="percents[1] > 0"></div>
        <div :style="{width: percents[2]+'%'}" class="red" v-if="percents[2] > 0"></div>
        <div :style="{width:  percents[3]+'%'}" class="grey" v-if="percents[3] > 0"></div>
    </div>


</template>


<script>
    export default {
        props: {
            tagdata: {
                type: Array
            }
        },
        computed: {
            percents() {
                let know = this.tagdata[0];
                let somewhatknow = this.tagdata[1];
                let dontknow = this.tagdata[2];
                let total = this.tagdata[3];

                let knowPercent = Math.round((know / total) * 100);
                let somewhatknowPercent = Math.round((somewhatknow / total) * 100);
                let dontknowPercent = Math.round((dontknow / total) * 100);

                let remaining =
                    100 - (knowPercent + somewhatknowPercent + dontknowPercent);

                return [
                    knowPercent,
                    somewhatknowPercent,
                    dontknowPercent,
                    remaining
                ];
            }
        },
        data: () => ({
            // show: false
        }),
        methods: {
            // attempt(id){
            //   // console.log(id)
            //   this.$router.push({ name: 'about', params: {id: id }})
            // }
        }
    };
</script>
<style>
    #fullbox {
        width: 100%;
        display: table;
    }

    #fullbox > div {
        height: 6px;
        display: table-cell;
    }

    .red {
        background-color: #ff5252 !important;
    }

    .green {
        background-color: #4caf50 !important;
    }

    .yellow {
        background-color: #fb8c00 !important;
    }

    .grey {
        background-color: grey;
    }
</style>
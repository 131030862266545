<template>
  <v-container v-cloak fluid grid-list-lg>
    <v-layout row wrap>
      <v-flex v-show="!subscribed" xs12>
        <PaymentAlert/>
      </v-flex>


      <v-flex sm9 xs12>
        <v-card>
          <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
            <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->

            <v-toolbar-title>Select A Subject</v-toolbar-title>

            <!-- <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>search</v-icon>
            </v-btn>-->
          </v-toolbar>


          <v-list two-line>
            <template v-for="(item, index) in items">
              <v-subheader v-if="item.header" :key="item.header" class="text--primary">{{ item.header }}
              </v-subheader>

              <v-divider v-else-if="item.divider" :key="index" :inset="item.inset" class="tile"/>


              <v-list-item
                  v-else
                  :key="item.S_name"
                  three-line

                  @click="openDialog(item)"
              >
                <v-list-item-avatar>
                  <v-img
                      :src="'https://res.cloudinary.com/drdx39vgf/image/upload/v1563777822/onlinePlatform/'+item.id+'.jpg'"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text--primary" v-html="item.S_name"/>

                  <v-list-item-subtitle class="text--primary">Practise {{ item.S_name }} MCQs
                  </v-list-item-subtitle>
                  <ViewTags :tagdata="item.fav"/>
                  <!--                                    <v-list-item-subtitle>-->
                  <!--                                        with TAG-->
                  <!--                                        <span v-html="type"/>-->
                  <!--                                    </v-list-item-subtitle>-->

                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="text-right">
                    Solved {{ item.fav[4] }} of {{ item.fav[3] }}
                  </v-list-item-action-text>
                </v-list-item-action>


              </v-list-item>

            </template>
          </v-list>

        </v-card>

      </v-flex>


      <v-row justify="center">
        <v-dialog v-if="Object.keys(selectedItem).length>0" v-model="dialog" fullscreen
                  hide-overlay transition="dialog-bottom-transition">

          <v-card>
            <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
              <v-btn dark icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ selectedSubject }}</v-toolbar-title>
              <!--                            <v-spacer/>-->
              <!--                            <v-toolbar-items>-->
              <!--                                <v-btn @click="dialog = false" dark text>Save</v-btn>-->
              <!--                            </v-toolbar-items>-->
            </v-toolbar>
            <v-row
                align="center"
                class="mt-3"
                justify="center"
            >
              <div class="text-center">


                <p class="subtitle-2">Select a Tag below:</p>

                <v-btn class="ma-2 pa-2" color="success" tile @click="selectTag(1)">Know {{
                    selectedItem.fav[0]
                  }}
                </v-btn>
                <v-btn class="ma-2 pa-2" color="warning" tile @click="selectTag(2)">Somewhat Know {{
                    selectedItem.fav[1]
                  }}
                </v-btn>
                <v-btn class="ma-2 pa-2" color="error" tile @click="selectTag(3)">Dont Know {{
                    selectedItem.fav[2]
                  }}
                </v-btn>
                <v-btn class="ma-2 pa-2" color="grey" dark tile @click="selectTag(4)">Untagged
                  {{
                    parseInt(selectedItem.fav[3]) - (parseInt(selectedItem.fav[0]) +
                        parseInt(selectedItem.fav[1]) + parseInt(selectedItem.fav[2]))
                  }}
                </v-btn>
                <v-btn class="ma-2 pa-2" color="blue" dark tile @click="selectTag(5)">ALL {{
                    selectedItem.fav[3]
                  }}
                </v-btn>

                <p/>
                <ViewTags :tagdata="selectedItem.fav" class="ma-2 pa-2"/>
                <p/>
                <p>You have solved {{ selectedItem.fav[4] }} of {{ selectedItem.fav[3] }} MCQs</p>

              </div>
              <div class="text-center">

              </div>
            </v-row>

            <v-row
                align="center"
                class="mt-3"
                justify="center">
              <div class="text-center full-width">

              </div>

            </v-row>
            <v-row
                align="center"
                class="mt-3"
                justify="center"
            >
              <v-btn class="mx-2" color="primary"
                     tile
                     @click="practiseThisSubject(selectedItem.id, selectedItem.S_name)">Start with Tag {{ tagName }}
              </v-btn>

            </v-row>

          </v-card>
        </v-dialog>
      </v-row>

    </v-layout>
  </v-container>
</template>

<script>
import ViewTags from "@/components/ViewTagsComponent";
import {APIService} from "../APIService";
import PaymentAlert from "@/components/PaymentAlert.vue";


export default {
  metaInfo: {
    title: 'Practise Subject Wise',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
      }
    ]
  },
  components: {
    ViewTags,
    PaymentAlert
  },
  created() {
    this.$store.commit("setShowProgress", true);

    if (localStorage.getItem("AllSubjects")) {
      this.items = JSON.parse(localStorage.getItem("AllSubjects"));
      this.$store.commit("setShowProgress", false);

    }
  },
  computed: {
    subscribed() {
      return this.$store.getters.getSubscriptionStatus;
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered

      this.getHomeFromServer();
    });
  },
  methods: {
    closeDialog() {
      this.selectedSubject = null;
      this.selectedSubjectID = null;
      this.selectedItem = {};
      this.dialog = false;
    },
    openDialog(item) {
      // eslint-disable-next-line no-console
      // console.log(item);
      this.selectedSubject = item.S_name;
      this.selectedSubjectID = item.id;
      this.selectedItem = item;
      this.dialog = true;
    },
    getHomeFromServer() {
      const app = this;

      APIService.getAllSubjectsFromServer().then(data => {
        if (data.status === 200) {
          app.items = data.data;
          // console.log("Loaded Subjects From net");
          // eslint-disable-next-line no-console

          app.$store.commit("setSubscriptionsStatus", data.subscribed);


          localStorage.setItem("AllSubjects", JSON.stringify(data.data));
          this.$store.commit("setShowProgress", false);
        }
      });
    },
    practiseThisSubject(val, subjectName) {
      // this.$router.push({ name: 'practise', params: { val } })
      this.$store.commit("SetPractiseSubject", subjectName);
      if (this.selectedTag !== 5) {
        this.$router.push({
          name: "practise",
          params: {id: val, tag: this.selectedTag}
        });
      } else {
        this.$router.push({name: "practise", params: {id: val}});
      }
    },
    selectTag(tag) {
      this.selectedTag = tag;
      switch ( tag ) {
        case 1:
          this.type = "<strong class='success white--text pa-2'>KNOW</strong>";
          this.tagName = "KNOW";
          break;
        case 2:
          this.type =
              "<strong class='warning white--text pa-2'>SOMEWHAT KNOW</strong>";
          this.tagName = "SOMEWHAT KNOW";

          break;
        case 3:
          this.type = "<strong class='error white--text pa-2'>DONT KNOW</strong>";
          this.tagName = "DONT KNOW";

          break;
        case 4:
          this.type = "<strong class='grey white--text pa-2'>UNTAGGED</strong>";
          this.tagName = "UNTAGGED";

          break;
        default:
          this.type = "<strong class='blue white--text pa-2'>ALL</strong>";
          this.tagName = "ALL";

          break;
      }
    }
  },

  data() {
    return {
      items: [],
      type: "<strong class='blue white--text pa-2'>ALL</strong>",
      selectedTag: 5,
      fab: false,

      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      selectedSubject: "",
      selectedSubjectID: "",
      selectedItem: [],
      tagName: "ALL"
    };
  },
};
</script>
<style scoped>
.tile {
  margin: 5px;
  border-radius: 4px;
  color: #af12a7;
}


/* .red {
  background-color: #ff5252 !important;
  color: #ffffff !important;
}
.green {
  background-color: #4caf50 !important;
  color: #ffffff !important;
}
.yellow {
  background-color: #fb8c00 !important;
  color: #ffffff !important;
}
.grey {
  background-color: grey;
  color: #ffffff !important;
} */
</style>
